import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { FunctionComponent } from "react";
import { NumberFormatValues, NumericFormat } from "react-number-format";
import { colors } from "../../muiTheme";

interface InputAmountProps {
  label: string;
  value?: number;
  endAdornment: string;
  error?: string;
  onChange?: (value?: number) => void;
}

const CustomInput: FunctionComponent<TextFieldProps> = (props) => {
  return <TextField autoComplete="off" {...props} />;
};

const InputAmount: FunctionComponent<InputAmountProps> = ({
  label,
  value,
  endAdornment,
  error,
  onChange,
}) => {
  const handleValueChange = (numberValue: NumberFormatValues) => {
    onChange && onChange(numberValue.floatValue);
  };

  return (
    <NumericFormat
      label={label}
      customInput={CustomInput}
      value={value}
      error={Boolean(error)}
      helperText={error}
      fullWidth
      focused
      type="tel"
      allowNegative={false}
      fixedDecimalScale={true}
      allowLeadingZeros={false}
      decimalScale={2}
      thousandSeparator={true}
      onValueChange={handleValueChange}
      sx={{
        "& label.Mui-focused": {
          color: colors.whiteGrey,
        },
        "& label.Mui-error": {
          color: colors.red,
        },
        "& .MuiOutlinedInput-root": {
          color: colors.white,
          "&.Mui-focused fieldset": {
            border: colors.boderYellow,
          },
          "&.Mui-error fieldset": {
            border: colors.borderRed,
          },
        },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="end"
            disableTypography={true}
            sx={{ color: colors.whiteGrey }}
          >
            {endAdornment}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default InputAmount;
