import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { FormHelperText, Grid, Stack } from "@mui/material";
import { FunctionComponent, useState } from "react";
import { colors } from "../../muiTheme";
import { ErrorTransfer, Merchant } from "../../typings/model";
import { ceil2digits, floor2digits } from "../../util/common";
import { TRADE_MODE } from "../../util/constants";
import InputAmount from "../InputAmount";

interface TransferProps {
  fromLabel: string;
  fromCurrency: string;
  toLabel: string;
  toCurrency: string;
  toHelperText?: string;
  merchant: Merchant;
  mode: TRADE_MODE;
  error: ErrorTransfer;
  onAmountChange: (payAmount?: number, receiveAmount?: number) => void;
}

const Transfer: FunctionComponent<TransferProps> = ({
  fromLabel,
  fromCurrency,
  toLabel,
  toCurrency,
  toHelperText,
  merchant,
  mode,
  error,
  onAmountChange,
}) => {
  const [fromAmount, setFromAmount] = useState<number>();
  const [toAmount, setToAmount] = useState<number>();

  const handleFromAmountChange = (value?: number) => {
    setFromAmount(value);
    if (value) {
      const convertValue = floor2digits(value * merchant.rate);
      setToAmount(convertValue);
      onAmountChange(value, convertValue);
    } else {
      onAmountChange(value, toAmount);
    }
  };

  const handleToAmountChange = (value?: number) => {
    setToAmount(value);
    if (value) {
      const convertValue = ceil2digits(value / merchant.rate);
      setFromAmount(convertValue);
      onAmountChange(convertValue, value);
    } else {
      onAmountChange(fromAmount, value);
    }
  };

  return (
    <Stack>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={10} lg={8} xl={6}>
          <InputAmount
            label={fromLabel}
            value={fromAmount}
            onChange={handleFromAmountChange}
            endAdornment={fromCurrency}
            error={error.payAmountErrorText}
          />
        </Grid>
      </Grid>

      <ArrowDownwardIcon
        sx={{ color: colors.white, my: 1, alignSelf: "center" }}
      />

      <Grid container justifyContent="center">
        <Grid item xs={12} sm={10} lg={8} xl={6}>
          <InputAmount
            label={toLabel}
            value={toAmount}
            onChange={handleToAmountChange}
            endAdornment={toCurrency}
            error={error.receiveAmountErrorText}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="center">
        <Grid item xs={12} sm={10} lg={8} xl={6}>
          {toHelperText && (
            <FormHelperText
              component="span"
              sx={{ color: colors.whiteGrey, fontSize: "12px", float: "left" }}
              dangerouslySetInnerHTML={{
                __html: toHelperText,
              }}
            ></FormHelperText>
          )}
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Transfer;
