import { Button } from "@mui/material";
import { Amplify } from "aws-amplify";
import { FunctionComponent } from "react";
import { isMobile } from "react-device-detect";
import awsconfig from "../../aws-exports";
import useLoadWeb3 from "../../hooks/useLoadWeb3";
import { colors } from "../../muiTheme";
Amplify.configure(awsconfig);

const Wallet: FunctionComponent = () => {
  const { loadWeb3 } = useLoadWeb3();

  const onClickConnect = async () => {
    if (isMobile) {
      if (typeof window.ethereum === "undefined") {
        if (window.location.href.includes("dev")) {
          window.location.href =
            "https://metamask.app.link/dapp/dev.automt.co/";
        } else {
          window.location.href = "https://metamask.app.link/dapp/automt.co/";
        }
      } else {
        await loadWeb3();
      }
    } else {
      await loadWeb3();
    }
  };

  return (
    <Button
      onClick={onClickConnect}
      disableRipple
      sx={{
        fontSize: "15px",
        background: colors.yellowDarkPrimary,
        border: colors.boderYellow,
        color: colors.blueDarkPrimary,
        padding: "6px 16px",
        mx: 2,

        "&.MuiButton-root:hover": {
          border: colors.boderYellow,
          background: colors.yellowDarkPrimary,
          color: colors.blueDarkPrimary,
        },
      }}
    >
      CONNECT
    </Button>
  );
};

export default Wallet;
