import { Dialog, DialogContent, Grid } from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { getOrderHistory } from "../../api/AmtApi";
import { colors } from "../../muiTheme";
import {
  loadingState,
  toastContentState,
  toastStateState,
} from "../../recoil/atom";
import { OrderHistoryRecord } from "../../typings/model.api";
import { getErrorMessage, getStatusCode } from "../../util/common";
import { STATUS_CODE } from "../../util/constants";
import DialogTitleWithClose from "../core/DialogTitleWithClose";
import OrderHistoryCard from "./OrderHistoryCard";

interface OrderHistoryDialogProps {
  open: boolean;
  onClose: () => void;
}
const OrderHistoryDialog: FunctionComponent<OrderHistoryDialogProps> = ({
  open,
  onClose,
}) => {
  const [loading, setLoading] = useRecoilState(loadingState);
  const setIsToastAlertOpen = useSetRecoilState(toastStateState);
  const setToastContent = useSetRecoilState(toastContentState);

  const [orders, setOrders] = useState<OrderHistoryRecord[]>([]);

  const fetchOrderHistory = async () => {
    setLoading(true);
    try {
      const response = await getOrderHistory();
      const statusCode = getStatusCode(response);

      if (statusCode !== STATUS_CODE[20000]) throw response;

      const { data } = response.data;

      setOrders(data.records);
      setLoading(false);
    } catch (error) {
      onClose();
      setToastContent(getErrorMessage(error));
      setIsToastAlertOpen(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchOrderHistory();
    }
  }, [open]);

  return (
    <>
      {!loading && (
        <Dialog
          fullWidth
          maxWidth="xs"
          onClose={onClose}
          open={open}
          PaperProps={{
            style: {
              backgroundColor: colors.blueDarkPrimary,
              boxShadow: "none",
              borderRadius: "20px",
            },
          }}
        >
          <DialogTitleWithClose onClose={onClose}>
            Order History
          </DialogTitleWithClose>
          <DialogContent>
            <Grid container spacing={3}>
              {orders.map((order) => (
                <Grid item xs={12} key={order.order_id}>
                  <OrderHistoryCard order={order}></OrderHistoryCard>
                </Grid>
              ))}
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default OrderHistoryDialog;
