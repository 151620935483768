import { Merchant } from "../typings/model";
import {
  MerchantRecord,
  MerchantRequest,
  PutProfileRequest,
} from "../typings/model.api";
import { ORDER_STATUS } from "./constants";

export const mapProfileRequest = (
  bankCode: string,
  bankAccount: string,
  name: string
): PutProfileRequest => {
  return {
    bank_code: bankCode,
    bank_account: bankAccount,
    name: name,
  };
};

export const mapMerchantRequest = (
  side: string,
  payCurrency: string,
  receivedCurrency: string
): MerchantRequest => {
  return {
    side: side,
    pay_currency: payCurrency,
    received_currency: receivedCurrency,
  };
};

export const mapMerchant = (response: MerchantRecord[]): Merchant[] => {
  return response.map((res) => ({
    available: res.available,
    completion: res.completion,
    maxAmount: res.max_amount,
    merchantId: res.shop_id,
    merchantName: res.shop_name,
    minAmount: res.min_amount,
    orders: res.orders,
    payCurrency: res.pay_currency,
    rate: res.rate,
    rateDisplay: res.rate_display,
    receivedCurrency: res.received_currency,
    side: res.side,
    startTime: res.start_time,
    status: res.status,
    totalAmount: res.total_amount,
    walletAddress: res.wallet_address,
    seller: res.seller
  }));
};

export const getStepFromStatus = (status: string) => {
  switch (status) {
    case ORDER_STATUS.OPEN:
      return 0;

    case ORDER_STATUS.CONFIRMED:
      return 1;

    case ORDER_STATUS.COMPLETED:
      return 2;

    default:
      return 0;
  }
};
