import { createTheme } from "@mui/material/styles";

export const colors = {
  yellowDarkPrimary: "#D4B361",
  blueDarkPrimary: "#08274E",
  white: "#FFFFFF",
  whiteGrey: "rgba(255, 255, 255, 0.7)",
  borderGrey: "1px solid rgba(224, 224, 224, 0.3)",
  boderYellow: "1px solid #D4B361",
  blue: "#4A91E9",
  darkBackground: "#0B203B",
  grey: "#BBC3CE",
  whiteBackground: "rgba(255, 255, 255, 0.08)",
  red: "#d32f2f",
  borderRed: "1px solid #d32f2f",

  darkBackgroundPaper: "#08274E",
  darkTextDiasbled: "rgba(255, 255, 255, 0.5)",
  darkSuccessMain: "#66BB6A",
  darkSecondaryMain: "#4A91E9",
  darkErrorMain: "#F44336",
};

export const muiTheme = createTheme({
  typography: {
    fontSize: 14,
    button: {
      textTransform: "none",
    },
  },
});
