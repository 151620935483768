import { t } from "../i18n";
import { PREFIX_LINE_URL } from "./constants";

export const getStatusCode = (error: any): string => {
  const response = error?.response || error;
  if (response?.data?.status?.code) {
    return response.data.status.code;
  } else if (response?.status?.code) {
    return response.status.code;
  }
  return "";
};

export const getErrorCode = (result: any): string => {
  if (!result) {
    return "5000X";
  }

  const response = result.response || result;
  const statusCode = getStatusCode(response);

  if (statusCode) {
    return statusCode;
  } else if (response?.status) {
    return response.status.toString();
  } else if (response.message) {
    return response.message;
  }

  return t.error.unknown;
};

export const getErrorMessage = (result: any, message?: string): string => {
  if (!result) return t.error.empty;

  const response = result.response || result;
  const statusCode = getStatusCode(response);
  if (statusCode) {
    const data = response?.data || response;
    const errorDescription =
      message || data?.status?.description || data?.status?.message || "";
    return errorDescription ? `${statusCode}: ${errorDescription}` : statusCode;
  } else {
    return message || result.message || t.error.unknown;
  }
};

export const getFallbackCode = (error: Error) => {
  const errorCode = getErrorCode(error);
  return errorCode.replace(/\(error:\W.+\)$/g, "");
};

export const floor2digits = (value: number) => {
  return Math.floor(value * 100) / 100;
};

export const ceil2digits = (value: number) => {
  return Math.ceil(value * 100) / 100;
};

export const formatCurrency = (
  currency: number | null | undefined,
  defaultValue: string = "-",
  decimal: number = 2
) => {
  if (typeof currency !== "number") {
    return defaultValue;
  }

  const currentRegex = /^(-|)([0-9]+)(\.\d{0,2})?$/;
  let formattedCurrency = "";

  if (currentRegex.test(String(currency))) {
    formattedCurrency = `${currency.toLocaleString("th-TH", {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    })}`;

    return formattedCurrency.trim();
  } else {
    return currency;
  }
};

export const formatCountdownTimer = (sec: number) => {
  const mm = Math.floor(sec / 60);
  const ss = Math.floor(sec % 60).toString();
  return `${mm}:${ss.padStart(2, "0")}`;
};

export const getRandomString = (bytes: any) => {
  const randomValues = new Uint8Array(bytes);
  window.crypto.getRandomValues(randomValues);
  return Array.from(randomValues).map(intToHex).join("");
};

const intToHex = (nr: any) => {
  return nr.toString(16).padStart(2, "0");
};

function getTimeUTCInSeconds(): number {
  return Math.floor(Date.now() / 1000);
}

export const getDiffTime = (time: number) => {
  const currentTime = getTimeUTCInSeconds();
  return time - currentTime;
};

export const convertEpochToDateTime = (epoch: number) => {
  const date = new Date(epoch * 1000);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  return `${year}-${month}-${day} (${hours}:${minutes}:${seconds})`;
};

export const truncate = (
  fullStr: string,
  strLen: number,
  separator?: string
) => {
  if (fullStr.length <= strLen) return fullStr;

  separator = separator || "...";

  var sepLen = separator.length,
    charsToShow = strLen - sepLen,
    frontChars = Math.ceil(charsToShow / 2),
    backChars = Math.floor(charsToShow / 2);

  return (
    fullStr.substr(0, frontChars) +
    separator +
    fullStr.substr(fullStr.length - backChars)
  );
};

export const generateAddLineUrl = (id: string) => {
  return PREFIX_LINE_URL + id.replace("~", "");
};

export const removePrefixLineId = (id: string) => {
  return id.replace("~", "");
};
