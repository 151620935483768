import MetaMaskOnboarding from "@metamask/onboarding";
import { Auth } from "aws-amplify";
import React from "react";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import { getProfile } from "../api/AmtApi";
import {
  addBankAccountState,
  loadingState,
  toastContentState,
  toastStateState,
  userState,
} from "../recoil/atom";
import {
  getErrorMessage,
  getRandomString,
  getStatusCode,
} from "../util/common";
import { STATUS_CODE } from "../util/constants";

const useLoadWeb3 = () => {
  const setLoading = useSetRecoilState(loadingState);
  const setIsToastAlertOpen = useSetRecoilState(toastStateState);
  const setToastContent = useSetRecoilState(toastContentState);
  const setUser = useSetRecoilState(userState);
  const setOpenAddBankAccount = useSetRecoilState(addBankAccountState);
  const resetUserState = useResetRecoilState(userState);
  const onboarding = React.useRef<MetaMaskOnboarding>();

  const loadWeb3 = async () => {
    try {
      setLoading(true);
      if (typeof window.ethereum !== "undefined") {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        if (accounts.length > 0) {
          const address = accounts[0];

          localStorage.setItem("walletAddress", address);

          const cognitoUser = await handleAmplifySignIn(address);
          const messageToSign = cognitoUser.challengeParam.message;
          const signature = await window.ethereum.request({
            method: "personal_sign",
            params: [address, messageToSign],
          });
          await Auth.sendCustomChallengeAnswer(cognitoUser, signature);
          await checkUser();
          setLoading(false);
        }
      } else {
        if (!onboarding.current) {
          onboarding.current = new MetaMaskOnboarding();
        }
        setLoading(false);
        onboarding.current.startOnboarding();
        throw new Error("Metamask not installed");
      }
    } catch (error: any) {
      setLoading(false);
      setToastContent(getErrorMessage(error));
      setIsToastAlertOpen(true);
    }
  };

  /*  const getSignature = async (address: any) => {
    const cognitoUser = await handleAmplifySignIn(address);
    const messageToSign = cognitoUser.challengeParam.message;
    const signature = await window.ethereum.request({
      method: "personal_sign",
      params: [address, messageToSign],
    });

    return signature;
  } */

  const checkChainId = async () => {
    const chainId = await window.ethereum.request({ method: "eth_chainId" });
    if (chainId != "0xDDD5") {
      window.ethereum
        .request({
          method: "wallet_addEthereumChain",
          /*params: [
            {
              chainId: "0x61", // A 0x-prefixed hexadecimal string
              chainName: "BSC Testnet",
              nativeCurrency: {
                name: "BNB",
                symbol: "BNB",
                decimals: 18,
              },
              rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545"],
              blockExplorerUrls: ["https://testnet.bscscan.com"],
            },
          ],*/
          params: [
            {
              chainId: "0xDDD5", // A 0x-prefixed hexadecimal string
              chainName: "Nova",
              nativeCurrency: {
                name: "NOVA",
                symbol: "NOVA",
                decimals: 18,
              },
              rpcUrls: ["https://nova.velo.org"],
              blockExplorerUrls: ["https://nova-scan.velo.org"],
            },
          ],
        })
        .then(async () => {
          return;
        })
        .catch((error: any) => {
          throw error;
        });
    }
  };

  const handleAmplifySignIn = async (address: any): Promise<any> => {
    try {
      const cognitoUser = await Auth.signIn(address);
      return cognitoUser;
    } catch (err: any) {
      /*Cognito doesn't give us a lot of flexibility on error responses
        so we'll have to string match our 'User Not Found' error here
        and create a cognito user with the address as their username if they don't exist*/
      if (
        err.toString().includes("UserNotFoundException") ||
        (err && err.message && err.message.includes("[404]"))
      ) {
        const params = {
          username: address,
          password: getRandomString(30),
        };
        await Auth.signUp(params);
        return handleAmplifySignIn(address);
      } else {
        throw err;
      }
    }
  };

  const checkUser = async () => {
    await checkChainId();
    try {
      //const cogintoUser = await Auth.currentSession();
      const _user = await Auth.currentAuthenticatedUser();
      const { jwtToken } = _user.signInUserSession.idToken;

      localStorage.setItem("jwtToken", jwtToken);

      const response = await getProfile();
      const statusCode = getStatusCode(response);

      if (statusCode !== STATUS_CODE[20000]) throw response;

      const { data } = response.data;
      setUser(data);

      if (!data.banks || data.banks.length === 0) {
        setOpenAddBankAccount(true);
      }
    } catch (error) {
      throw error;
    }
  };

  const checkConnection = async () => {
    try {
      setLoading(true);
      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });

      const currentAccount = localStorage.getItem("walletAddress");

      if (accounts.length === 0) {
        localStorage.removeItem("walletAddress");
        localStorage.removeItem("jwtToken");

        throw new Error("You're not connected to MetaMask");
      } else if (accounts[0] !== currentAccount) {
        const address = accounts[0];
        localStorage.setItem("walletAddress", address);

        const cognitoUser = await handleAmplifySignIn(address);
        const messageToSign = cognitoUser.challengeParam.message;
        const signature = await window.ethereum.request({
          method: "personal_sign",
          params: [address, messageToSign],
        });
        await Auth.sendCustomChallengeAnswer(cognitoUser, signature);
        await checkUser();
      } else {
        await checkUser();
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setToastContent(getErrorMessage(error));
      setIsToastAlertOpen(true);
    }
  };
  if (typeof window.ethereum !== "undefined") {
    window.ethereum.on("accountsChanged", () => {
      resetUserState();
      localStorage.removeItem("walletAddress");
      localStorage.removeItem("jwtToken");
      checkConnection();
    });
  }

  return { loadWeb3, checkConnection };
};

export default useLoadWeb3;
