import axios from "axios";
import config from "../config/api.json";
import {
  BankInformation,
  DataResult,
  MerchantRequest,
  MerchantResponse,
  OrderHistoryResponse,
  OrderRequest,
  OrderResponse,
  PutProfileRequest,
  User,
} from "../typings/model.api";
import { appendCognitoToken } from "./interceptor";

export const amtAxios = axios.create({
  baseURL: config.api_host,
});

amtAxios.interceptors.request.use(appendCognitoToken);

export const getBankInformtaion = async () => {
  return amtAxios.get<DataResult<BankInformation[]>>("/options/bank_code");
};

export const getProfile = async () => {
  return amtAxios.get<DataResult<User>>("/profile");
};

export const putProfile = async (params: PutProfileRequest) => {
  return amtAxios.put<DataResult<User>>("/profile", params);
};

export const getListMerchant = async (params: MerchantRequest) => {
  return amtAxios.get<DataResult<MerchantResponse>>("/shops", {
    params: params,
  });
};

export const createOrderBuy = async (payload: OrderRequest) => {
  return amtAxios.post<DataResult<OrderResponse>>("/orders/buy", payload);
};

export const createOrderSell = async (payload: OrderRequest) => {
  return amtAxios.post<DataResult<OrderResponse>>("/orders/sell", payload);
};

export const confirmOrder = async (id: string, payload: any) => {
  return amtAxios.put<DataResult<OrderResponse>>(
    `/orders/${id}/confirm`,
    payload
  );
};

export const cancelOrder = async (id: string) => {
  return amtAxios.put<DataResult<OrderResponse>>(`/orders/${id}/cancel`);
};

export const getOrderDetail = async (id: string) => {
  return amtAxios.get<DataResult<OrderResponse>>(`/orders/${id}`);
};

export const getOrderHistory = async () => {
  return amtAxios.get<DataResult<OrderHistoryResponse>>("/order-history");
};
