import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Router } from "react-router-dom";
import { RecoilRoot } from "recoil";
import App from "./App";
import "./index.css";
import { muiTheme } from "./muiTheme";
import { history } from "./recoil/history";

const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);

root.render(
  <StrictMode>
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      <RecoilRoot>
        <Router history={history}>
          <App />
        </Router>
      </RecoilRoot>
    </ThemeProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
