import {
  Box,
  Button,
  Collapse,
  Grid,
  styled,
  TableCell,
  tableCellClasses,
  TableRow,
  Typography,
} from "@mui/material";
import { FunctionComponent, useState } from "react";
import { colors } from "../../muiTheme";
import { Merchant } from "../../typings/model";
import { formatCurrency } from "../../util/common";
import { TRADE_MODE } from "../../util/constants";
import TransferBuy from "../TransferBuy";
import TransferSell from "../TransferSell";

const StyledTableCell = styled(TableCell)({
  [`&.${tableCellClasses.body}`]: {
    color: colors.white,
    fontSize: 14,
  },
});

const StyledTableRow = styled(TableRow)({
  backgroundColor: colors.blueDarkPrimary,
});

const StyledBox = styled(Box)({
  borderRadius: "100px",
  border: colors.borderGrey,
  padding: "7px 10px",
});

export const ActionButton = styled(Button)({
  background: colors.yellowDarkPrimary,
  boxShadow:
    "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
  borderRadius: "4px",
  color: colors.blueDarkPrimary,
  padding: "8px 22px",
  fontWeight: 500,
  fontSize: "15px",
  lineHeight: "26px",

  "&:hover": {
    background: colors.yellowDarkPrimary,
    color: colors.blueDarkPrimary,
  },

  "&:disabled": {
    backgroundColor: "rgba(255, 255, 255, 0.12)",
    color: "rgba(235, 235, 235, 0.3)",
  },
});

export const ActionOutlineButton = styled(Button)({
  background: colors.blueDarkPrimary,
  border: colors.boderYellow,
  borderRadius: "4px",
  color: colors.yellowDarkPrimary,
  padding: "8px 22px",
  fontWeight: 500,
  fontSize: "15px",
  lineHeight: "26px",
});

interface RowMerchantProps {
  row: Merchant;
  mode: TRADE_MODE;
}

const RowMerchant: FunctionComponent<RowMerchantProps> = ({ row, mode }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <StyledTableRow>
        <StyledTableCell
          component="th"
          scope="row"
          sx={{
            borderBottom: open ? "none" : colors.borderGrey,
          }}
        >
          <Grid container direction="column">
            <Grid item>
              <Typography
                component="h3"
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: "700",
                  fontSize: "20px",
                  lineHeight: "32px",
                  color: colors.white,
                }}
              >
                {row.merchantName}
              </Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item>
                <StyledBox>{`${row.seller.orders} orders`}</StyledBox>
              </Grid>
              <Grid item>
                <StyledBox>{`${row.seller.completion}% completion`}</StyledBox>
              </Grid>
            </Grid>
          </Grid>
        </StyledTableCell>
        <StyledTableCell
          sx={{
            borderBottom: open ? "none" : colors.borderGrey,
          }}
        >
          <Typography
            component="span"
            gutterBottom
            sx={{
              fontWeight: "600",
              fontSize: "26px",
              lineHeight: "34px",
              color: colors.yellowDarkPrimary,
            }}
          >
            {row.rateDisplay}
          </Typography>
          <Typography
            component="span"
            gutterBottom
            sx={{
              fontSize: "14px",
              lineHeight: "20px",
              color: colors.yellowDarkPrimary,
              verticalAlign: "top",
            }}
          >
            {" "}
            THB
          </Typography>
        </StyledTableCell>
        <StyledTableCell
          sx={{
            borderBottom: open ? "none" : colors.borderGrey,
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              lineHeight: "20px",
              color: colors.whiteGrey,
            }}
          >
            Available
          </Typography>
          <Typography
            gutterBottom
            sx={{
              fontSize: "14px",
              lineHeight: "20px",
              color: colors.white,
            }}
          >
            {`${formatCurrency(row.available)} USDV`}
          </Typography>

          <Typography
            sx={{
              fontSize: "14px",
              lineHeight: "20px",
              color: colors.whiteGrey,
            }}
          >
            Limit
          </Typography>

          <Typography
            gutterBottom
            sx={{
              fontSize: "14px",
              lineHeight: "20px",
              color: colors.white,
            }}
          >
            {`${formatCurrency(row.minAmount)} - ${formatCurrency(
              row.maxAmount
            )} THB`}
          </Typography>
        </StyledTableCell>
        <StyledTableCell
          sx={{
            borderBottom: open ? "none" : colors.borderGrey,
          }}
        >
          {open ? (
            <ActionOutlineButton onClick={() => setOpen(!open)}>
              CANCEL
            </ActionOutlineButton>
          ) : (
            <ActionButton onClick={() => setOpen(!open)}>
              {mode === TRADE_MODE.BUY
                ? `BUY ${row.receivedCurrency}`
                : `SELL ${row.payCurrency}`}
            </ActionButton>
          )}
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <TableCell
          sx={{
            borderBottom: open ? colors.borderGrey : "none",
            paddingBottom: 0,
            paddingTop: 0,
          }}
          colSpan={5}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid container justifyContent="center">
              <Grid item xs={10}>
                <Box sx={{ pt: 2 }}>
                  {mode === TRADE_MODE.BUY && <TransferBuy merchant={row} />}
                  {mode === TRADE_MODE.SELL && <TransferSell merchant={row} />}
                </Box>
              </Grid>
            </Grid>
          </Collapse>
        </TableCell>
      </StyledTableRow>
    </>
  );
};

export default RowMerchant;
