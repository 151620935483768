import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Link,
  styled,
  Typography,
} from "@mui/material";
import { FunctionComponent } from "react";
import { colors } from "../../muiTheme";
import { OrderHistoryRecord } from "../../typings/model.api";
import { convertEpochToDateTime, formatCurrency } from "../../util/common";

const LabelText = styled(Typography)({
  fontSize: "14px",
  letterSpacing: "0.17px",
  lineHeight: "20px",
  color: colors.whiteGrey,
});

const ValueText = styled(Typography)({
  fontSize: "14px",
  letterSpacing: "0.17px",
  lineHeight: "20px",
  color: colors.white,
});

interface OrderHistoryCardProps {
  order: OrderHistoryRecord;
}
const OrderHistoryCard: FunctionComponent<OrderHistoryCardProps> = ({
  order,
}) => {
  return (
    <Card
      sx={{
        background: colors.blueDarkPrimary,
        borderRadius: "20px",
      }}
    >
      <CardContent>
        <Grid container>
          <Grid item xs={12} display="flex" justifyContent="space-between">
            <Typography
              component="div"
              sx={{
                fontSize: "20px",
                fontWeight: 700,
                letterSpacing: "0.15px",
                lineHeight: "32px",
                color: colors.yellowDarkPrimary,
              }}
            >
              {order.side.toLocaleUpperCase()}
            </Typography>
            <Typography
              component="div"
              sx={{
                fontSize: "20px",
                fontWeight: 700,
                letterSpacing: "0.15px",
                lineHeight: "32px",
                color: colors.yellowDarkPrimary,
              }}
            >
              {order.pay_currency} &nbsp;{formatCurrency(order.pay_amount)}
            </Typography>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="space-between">
            <LabelText>Transaction</LabelText>
            <ValueText>{order.order_id}</ValueText>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="space-between">
            <LabelText>Blockchain URL</LabelText>
            <Typography
              component={Link}
              href={order.txn_url || "/"}
              target="_blank"
              rel="noreferrer"
              sx={{
                fontSize: "14px",
                letterSpacing: "0.17px",
                lineHeight: "20px",
                color: colors.white,
              }}
            >
              {order.txn_url || "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="space-between">
            <LabelText>{convertEpochToDateTime(order.created_at)}</LabelText>
            <ValueText sx={{ color: colors.yellowDarkPrimary }}>
              {order.status}
            </ValueText>
          </Grid>
        </Grid>
      </CardContent>

      <CardActions sx={{ background: colors.yellowDarkPrimary, p: 0 }}>
        <Button
          component={Link}
          href={`/order/${order.order_id}`}
          fullWidth
          disableRipple
          sx={{ color: colors.blueDarkPrimary, fontSize: "14px" }}
        >
          See details
        </Button>
      </CardActions>
    </Card>
  );
};

export default OrderHistoryCard;
