import {
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { FunctionComponent, useState } from "react";
import { colors } from "../../muiTheme";
import DialogTitleWithClose from "../core/DialogTitleWithClose";
import { ActionButton } from "../RowMerchant";

interface DisclaimerDialogProps {
  title: string;
  open: boolean;
  onClose: () => void;
  onConfirm: (understood: boolean) => void;
}

const DisclaimerDialog: FunctionComponent<DisclaimerDialogProps> = ({
  title,
  open,
  onClose,
  onConfirm,
}) => {
  const [checked, setChecked] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleClose = () => {
    onClose();
    setChecked(false);
  };

  const onClickConfirm = () => {
    onConfirm(checked);
    setChecked(false);
  };

  return (
    <Dialog
      fullWidth
      onClose={handleClose}
      maxWidth="xs"
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: colors.blueDarkPrimary,
          boxShadow: "none",
          borderRadius: "20px",
          maxHeight: 400,
        },
      }}
    >
      <DialogTitleWithClose onClose={handleClose}>
        Disclaimer
      </DialogTitleWithClose>
      <DialogContent>
        <Typography
          sx={{
            fontSize: "14px",
            color: colors.white,
            pb: "16px",
          }}
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        ></Typography>

        <FormGroup>
          <FormControlLabel
            sx={{
              color: colors.yellowDarkPrimary,
              pb: "16px",
            }}
            control={
              <Checkbox
                onChange={handleChange}
                sx={{
                  color: colors.yellowDarkPrimary,
                  "&.Mui-checked": {
                    color: colors.yellowDarkPrimary,
                  },
                }}
              />
            }
            label="I understood."
          />
        </FormGroup>

        <ActionButton
          fullWidth
          disabled={!checked}
          disableRipple
          onClick={onClickConfirm}
        >
          CONFIRM
        </ActionButton>
      </DialogContent>
    </Dialog>
  );
};

export default DisclaimerDialog;
