import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
} from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { createOrderBuy } from "../../api/AmtApi";
import useLoadWeb3 from "../../hooks/useLoadWeb3";
import { t } from "../../i18n";
import { colors } from "../../muiTheme";
import {
  addBankAccountState,
  loadingState,
  openQuickPayDialogState,
  toastContentState,
  toastStateState,
  userState,
} from "../../recoil/atom";
import { history } from "../../recoil/history";
import { DescriptionText, ErrorTransfer, Merchant } from "../../typings/model";
import { OrderRequest, User } from "../../typings/model.api";
import {
  formatCurrency,
  getErrorMessage,
  getStatusCode,
} from "../../util/common";
import {
  DESTINATION,
  DESTINATION_DESCRIPTION,
  STATUS_CODE,
  TRADE_MODE,
} from "../../util/constants";
import { initDestinationList, initTransferError } from "../../util/initial";
import DisclaimerDialog from "../DisclaimerDialog";
import { ActionButton } from "../RowMerchant";
import Transfer from "../Transfer";

const TransferCard = styled(Card)({
  background: colors.blueDarkPrimary,
  borderRadius: "20px",
  border: colors.boderYellow,
});

const TransferCardHeader = styled(CardHeader)({
  background: colors.whiteBackground,
  borderBottom: "none",
  color: colors.white,
  padding: "8px",
});

const TransferFormHelperText = styled(FormHelperText)({
  color: colors.whiteGrey,
  fontSize: "12px",
});

const findChain = (code: string) => {
  return DESTINATION_DESCRIPTION.find((d) => d.partner_code === code)?.chain;
};

const buildDescription = (destination: string, user?: User) => {
  switch (destination) {
    case DESTINATION.METAMASK:
      const chain = { label: "Chain:", value: findChain(destination) };
      const walletAddress = {
        label: "Wallet address:",
        value: user?.wallet_address || "-",
      };
      return [chain, walletAddress];

    case DESTINATION.HYBRID_DEX:
      const dexUserId =
        user?.wallets.find((w) => w.partner_code === DESTINATION.HYBRID_DEX)
          .id || "-";
      const dexUser = { label: "User ID:", value: dexUserId };

      return [dexUser];

    default:
      return [];
  }
};

interface TransferBuyProps {
  merchant: Merchant;
  isMobileSize?: boolean;
  onCancel?: () => void;
}

const TransferBuy: FunctionComponent<TransferBuyProps> = ({
  merchant,
  isMobileSize,
  onCancel,
}) => {
  const { checkConnection } = useLoadWeb3();

  const user = useRecoilValue(userState);
  const setLoading = useSetRecoilState(loadingState);
  const setIsToastAlertOpen = useSetRecoilState(toastStateState);
  const setToastContent = useSetRecoilState(toastContentState);
  const setOpenAddBankAccount = useSetRecoilState(addBankAccountState);

  const destination0Code =
    user?.wallets[0].partner_code || initDestinationList[0].partner_code;

  const [payAmount, setPayAmount] = useState<number>();
  const [receiveAmount, setReceiveAmount] = useState<number>();
  const [destinationList, setDestinationList] = useState(
    user?.wallets || initDestinationList
  );
  const [destination, setDestination] = useState(destination0Code);
  const [descriptions, setDescriptions] = useState<DescriptionText[]>(
    buildDescription(destination0Code, user)
  );
  const [openDisclaimerDialog, setOpenDisclaimerDialog] = useState(false);
  const [toHelperText, setToHelperText] = useState("");
  const [trasferError, setTransferError] =
    useState<ErrorTransfer>(initTransferError);

  const onDestinationChange = (event: SelectChangeEvent) => {
    const dest = event.target.value;
    setDescriptions(buildDescription(dest, user));
    setDestination(dest);
    buildHelperText(dest);
  };

  const [openQuickPayDialog, setOpenQuickPayDialog] = useRecoilState(
    openQuickPayDialogState
  );

  const onAmountChange = (payAmount?: number, receiveAmount?: number) => {
    setPayAmount(payAmount);
    setReceiveAmount(receiveAmount);
  };

  const onTrade = async () => {
    if (validate()) {
      if (user) {
        if (!user.banks) {
          setOpenQuickPayDialog(false);
          setOpenAddBankAccount(true);
        } else {
          setOpenDisclaimerDialog(true);
        }
      } else {
        await checkConnection();
      }
    }
  };

  const validate = () => {
    let result = true;
    let {
      isPayAmountInvalid,
      isReceiveAmountInvalid,
      payAmountErrorText,
      receiveAmountErrorText,
    } = trasferError;

    if (!payAmount) {
      isPayAmountInvalid = true;
      payAmountErrorText = t.validation.required;
      result = false;
    } else {
      if (payAmount < merchant.minAmount) {
        isPayAmountInvalid = true;
        payAmountErrorText = t.validation.minimumLimit;
        isReceiveAmountInvalid = true;
        receiveAmountErrorText = t.validation.minimumLimit;
        result = false;
      }

      if (payAmount > merchant.maxAmount) {
        isPayAmountInvalid = true;
        payAmountErrorText = t.validation.maximumLimit;
        isReceiveAmountInvalid = true;
        receiveAmountErrorText = t.validation.maximumLimit;
        result = false;
      }
    }

    if (!receiveAmount) {
      isReceiveAmountInvalid = true;
      receiveAmountErrorText = t.validation.required;
      result = false;
    } else {
      if (receiveAmount > merchant.available) {
        isPayAmountInvalid = true;
        payAmountErrorText = t.validation.moreThanAvailable;
        isReceiveAmountInvalid = true;
        receiveAmountErrorText = t.validation.moreThanAvailable;
        result = false;
      }

      if (destination === DESTINATION.HYBRID_DEX) {
        const isDex = user?.wallets.find(
          (w) => w.partner_code === DESTINATION.HYBRID_DEX
        );

        if (isDex && receiveAmount < isDex.min_amount) {
          isPayAmountInvalid = true;
          payAmountErrorText = t.validation.lessThanMinimumDeposit;
          isReceiveAmountInvalid = true;
          receiveAmountErrorText = t.validation.lessThanMinimumDeposit;
          result = false;
        }
      }
    }

    setTransferError(
      result
        ? initTransferError
        : {
            isPayAmountInvalid,
            payAmountErrorText,
            isReceiveAmountInvalid,
            receiveAmountErrorText,
          }
    );
    return result;
  };

  const onCloseDisclaimerDialog = () => {
    setOpenDisclaimerDialog(false);
  };

  const onConfirmDisclaimerDialog = async (understood: boolean) => {
    if (understood) {
      try {
        setLoading(true);

        const receivedAddress = user?.wallets.find(
          (w) => w.partner_code === destination
        )?.address;

        if (!receivedAddress) throw new Error("ReceivedAddress not found");

        const paramsReq: OrderRequest = {
          shop_id: merchant.merchantId,
          pay_amount: payAmount || 0,
          user_wallet: user.wallet_address,
          received_address: receivedAddress,
        };

        const response = await createOrderBuy(paramsReq);
        const statusCode = getStatusCode(response);

        if (statusCode !== STATUS_CODE[20000]) throw response;

        const { order_id } = response.data.data;
        setLoading(false);

        history.push(`/order/${order_id}`);
      } catch (error) {
        setLoading(false);
        setToastContent(getErrorMessage(error));
        setIsToastAlertOpen(true);
      }
    }
    setOpenDisclaimerDialog(false);
  };

  const buildHelperText = (destination: string) => {
    if (destination === DESTINATION.HYBRID_DEX) {
      const isDex = user?.wallets.find(
        (w) => w.partner_code === DESTINATION.HYBRID_DEX
      );

      setToHelperText(
        isDex
          ? `<strong>Minimum Deposit</strong>: ${formatCurrency(
              isDex.min_amount,
              "-",
              0
            )} ${merchant.receivedCurrency.toLocaleUpperCase()}`
          : ""
      );
    } else {
      setToHelperText("");
    }
  };

  useEffect(() => {
    if (user) {
      setDestinationList(user.wallets);
      setDestination(user.wallets[0].partner_code);
      setDescriptions(buildDescription(user.wallets[0].partner_code, user));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <TransferCard
        variant="outlined"
        sx={{
          mt: 1,
          mb: isMobileSize ? 0 : 3,
          border: isMobileSize ? "none" : colors.boderYellow,
          borderRadius: isMobileSize ? "0px" : "20px",
        }}
      >
        {!isMobileSize && (
          <TransferCardHeader
            title="Payment time limit"
            subheader="15 Minutes"
            sx={{ textAlign: "center" }}
            titleTypographyProps={{
              fontSize: "10px",
              letterSpacing: "0.4px",
              color: colors.whiteGrey,
            }}
            subheaderTypographyProps={{
              fontSize: "14px",
              letterSpacing: "0.17px",
              color: colors.white,
            }}
          />
        )}

        <CardContent
          sx={{
            textAlign: "center",
            my: isMobileSize ? 0 : 2,
            p: isMobileSize ? 0 : 2,
          }}
        >
          {isMobileSize && (
            <>
              <TransferCardHeader
                title="Payment time limit"
                subheader="15 Minutes"
                sx={{
                  textAlign: "center",
                  width: "100vw",
                  left: 0,
                  height: "56px",
                  position: "absolute",
                }}
                titleTypographyProps={{
                  fontSize: "10px",
                  letterSpacing: "0.4px",
                  color: colors.whiteGrey,
                }}
                subheaderTypographyProps={{
                  fontSize: "14px",
                  letterSpacing: "0.17px",
                  color: colors.white,
                }}
              />
              <Box pt={12}></Box>
            </>
          )}

          <Transfer
            fromLabel="You pay"
            fromCurrency={merchant.payCurrency.toLocaleUpperCase()}
            toLabel="You receive"
            toCurrency={merchant.receivedCurrency.toLocaleUpperCase()}
            toHelperText={toHelperText}
            merchant={merchant}
            mode={TRADE_MODE.BUY}
            error={trasferError}
            onAmountChange={onAmountChange}
          ></Transfer>

          <Grid container justifyContent="center">
            <Grid item xs={12} md={10} lg={8} xl={6}>
              <FormControl
                variant="filled"
                fullWidth
                sx={{ mt: { xs: isMobileSize ? 9 : 6, md: 9 } }}
              >
                <InputLabel
                  sx={{
                    fontSize: "12px",
                    color: colors.whiteGrey,
                    letterSpacing: "0.15px",

                    "&.Mui-focused": {
                      color: colors.whiteGrey,
                    },
                  }}
                >
                  Receive destination
                </InputLabel>
                <Select
                  value={destination}
                  onChange={onDestinationChange}
                  sx={{
                    background: "rgba(255, 255, 255, 0.09)",
                    color: colors.white,
                    textAlign: "left",

                    "&:hover, &.Mui-focused": {
                      background: "rgba(255, 255, 255, 0.09)",
                      color: colors.white,
                    },

                    "&.MuiFilledInput-underline::before": {
                      borderColor: "rgba(255, 255, 255, 0.42)",
                    },

                    "&.MuiFilledInput-underline::after": {
                      borderColor: colors.yellowDarkPrimary,
                    },

                    ".MuiSvgIcon-root": {
                      fill: "white !important",
                    },
                  }}
                >
                  {destinationList.map((d, index) => {
                    return (
                      d.active && (
                        <MenuItem value={d.partner_code} key={index}>
                          {d.partner}
                        </MenuItem>
                      )
                    );
                  })}
                </Select>
                {descriptions.map((d, index) => (
                  <TransferFormHelperText key={index}>
                    <strong>{d.label}</strong> {d.value}
                  </TransferFormHelperText>
                ))}
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="center"
            sx={{ pt: 3 }}
            spacing={isMobileSize ? 2 : 0}
          >
            {isMobileSize && (
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  fullWidth
                  disableRipple
                  onClick={onCancel}
                  sx={{
                    fontSize: "14px",
                    color: colors.yellowDarkPrimary,
                    border: colors.boderYellow,
                    padding: isMobileSize ? "6px 22px" : "8px 22px",

                    "&.MuiButton-root:hover": {
                      border: colors.boderYellow,
                    },
                  }}
                >
                  CANCEL
                </Button>
              </Grid>
            )}
            <Grid item xs={isMobileSize ? 6 : 12} md={10} lg={8} xl={6}>
              <ActionButton
                fullWidth
                disableRipple
                onClick={onTrade}
                sx={{
                  padding: isMobileSize ? "6px 22px" : "8px 22px",
                  fontSize: isMobileSize ? "14px" : "16px",
                }}
              >
                BUY {merchant.receivedCurrency.toLocaleUpperCase()}
              </ActionButton>
            </Grid>
          </Grid>
        </CardContent>
      </TransferCard>

      <DisclaimerDialog
        title={t.disclaimer.buy}
        open={openDisclaimerDialog}
        onClose={onCloseDisclaimerDialog}
        onConfirm={onConfirmDisclaimerDialog}
      ></DisclaimerDialog>
    </>
  );
};

export default TransferBuy;
