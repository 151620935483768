import { Wallet } from "../typings/model";

export const initTransferError = {
  isPayAmountInvalid: false,
  isReceiveAmountInvalid: false,
  payAmountErrorText: undefined,
  receiveAmountErrorText: undefined,
};

export const initDestinationList: Wallet[] = [
  {
    address: "",
    id: "",
    partner: "MetaMask",
    partner_code: "metamask",
    active: true,
  },
];
