import CloseIcon from "@mui/icons-material/Close";
import { Alert } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { FC } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { toastContentState, toastStateState } from "../../../recoil/atom";

const ToastAlert: FC = () => {
  const isOpen = useRecoilValue(toastStateState);
  const content = useRecoilValue(toastContentState);
  const resetToastState = useResetRecoilState(toastStateState);
  const resetToastContent = useResetRecoilState(toastContentState);

  return (
    <Snackbar
      autoHideDuration={6000}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={isOpen}
      onClose={resetToastState}
      TransitionProps={{ onExited: resetToastContent }}
    >
      <Alert
        sx={{ width: "100%" }}
        severity="error"
        action={<CloseIcon onClick={resetToastState} />}
      >
        {content}
      </Alert>
    </Snackbar>
  );
};

export default ToastAlert;
