import { FC, useEffect, useState } from "react";
import { FallbackProps } from "react-error-boundary";
import bgError from "../../../assets/images/error.svg";
import Poster from "../../../components/core/Poster";
import { t } from "../../../i18n";
import { getFallbackCode } from "../../../util/common";
import { useResetRecoilState } from "recoil";
import { userState } from "../../../recoil/atom";
import { history } from "../../../recoil/history";

const ErrorFallback: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const [errorCode] = useState(() => getFallbackCode(error));
  const resetUserState = useResetRecoilState(userState);
  
  useEffect(() => {
    return () => {
      resetUserState();
      localStorage.removeItem("walletAddress");
      localStorage.removeItem("jwtToken");
      resetErrorBoundary();
      history.push('/');
    };
  }, []);

  return (
    <Poster
      image={bgError}
      info={t.error.errorFallback}
      errorCode={errorCode}
    ></Poster>
  );
};

export default ErrorFallback;
