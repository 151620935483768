import { AppBar, CardMedia, IconButton, Link, Toolbar } from "@mui/material";
import { FunctionComponent } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import AMTLogo from "../../assets/images/amt-logo.png";
import { colors } from "../../muiTheme";
import { addBankAccountState, userState } from "../../recoil/atom";
import AddBankAccountDialog from "../AddBankAccountDialog";
import MenuUser from "../MenuUser";
import Wallet from "../Wallet";

const Header: FunctionComponent = (props: any) => {
  const user = useRecoilValue(userState);
  const openAddBankAccount = useRecoilValue(addBankAccountState);
  const resetAddBankAccountState = useResetRecoilState(addBankAccountState);

  return (
    <AppBar
      position="absolute"
      elevation={0}
      sx={{
        position: "relative",
        background: colors.darkBackground,
        py: 1,
      }}
    >
      <Toolbar>
        <IconButton
          component={Link}
          disableRipple
          href="/"
          sx={{ flexGrow: 1, justifyContent: "left", py: 0 }}
        >
          <CardMedia
            component="img"
            image={AMTLogo}
            sx={{ height: "50px", width: "126px" }}
          />
        </IconButton>

        {user ? <MenuUser /> : <Wallet />}
      </Toolbar>

      <AddBankAccountDialog
        open={openAddBankAccount}
        onClose={resetAddBankAccountState}
      ></AddBankAccountDialog>
    </AppBar>
  );
};

export default Header;
