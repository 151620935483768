import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  styled,
  Typography,
} from "@mui/material";
import { FunctionComponent } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { colors } from "../../muiTheme";
import { addBankAccountState, userState } from "../../recoil/atom";
import DialogTitleWithClose from "../core/DialogTitleWithClose";

export const LabelBankForm = styled(Typography)({
  fontSize: "10px",
  color: colors.whiteGrey,
});

export const ValueBankForm = styled(Typography)({
  fontSize: "16px",
  lineHeight: "24px",
  color: colors.white,
});

interface BankAccountDialogProps {
  open: boolean;
  onClose: () => void;
}
const BankAccountDialog: FunctionComponent<BankAccountDialogProps> = ({
  open,
  onClose,
}) => {
  const user = useRecoilValue(userState);
  const setOpenAddBankAccount = useSetRecoilState(addBankAccountState);

  const handleAddBank = () => {
    setOpenAddBankAccount(true);
    onClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      onClose={onClose}
      open={open}
      PaperProps={{
        style: {
          backgroundColor: colors.blueDarkPrimary,
          boxShadow: "none",
          borderRadius: "20px",
        },
      }}
    >
      <DialogTitleWithClose onClose={onClose}>
        Bank Account
      </DialogTitleWithClose>
      <DialogContent>
        <Alert
          severity="warning"
          icon={<ErrorOutlineIcon sx={{ color: colors.yellowDarkPrimary }} />}
          sx={{
            background: colors.whiteBackground,
            color: colors.yellowDarkPrimary,
            fontSize: "14px",
            lineHeight: "20px",
            mb: 3,
          }}
        >
          If you want to change bank account, please contact
          contact.automt@gmail.com
        </Alert>
        {user?.banks &&
          user?.banks.map((bank, index) => (
            <>
              <Grid container spacing={2} key={index} sx={{ pb: 2, pt: 2 }}>
                <Grid item xs={12}>
                  <LabelBankForm>Bank</LabelBankForm>
                  <ValueBankForm>{bank.bank_name || "-"}</ValueBankForm>
                </Grid>
                <Grid item xs={12}>
                  <LabelBankForm>Account Number</LabelBankForm>
                  <ValueBankForm>{bank.bank_account || "-"}</ValueBankForm>
                </Grid>
                <Grid item xs={12}>
                  <LabelBankForm>Account Name (optional)</LabelBankForm>
                  <ValueBankForm>{bank.name || "-"}</ValueBankForm>
                </Grid>
              </Grid>
              {index + 1 !== user.banks?.length && (
                <Divider sx={{ bgcolor: "#D4B361" }} />
              )}
            </>
          ))}
      </DialogContent>
      <DialogActions
        sx={{
          padding: "20px 24px",
        }}
      >
        <Button
          onClick={handleAddBank}
          variant="outlined"
          disableRipple
          fullWidth
          sx={{
            color: colors.yellowDarkPrimary,
            border: colors.boderYellow,
            fontSize: "14px",
            fontWeight: 500,

            "&.MuiButton-root:hover": {
              border: colors.boderYellow,
            },
          }}
        >
          Add Bank
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BankAccountDialog;
