import MenuIcon from "@mui/icons-material/Menu";
import { Button, ClickAwayListener, Menu, MenuItem, Tooltip, styled } from "@mui/material";
import { FunctionComponent, useState } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { colors } from "../../muiTheme";
import { userState } from "../../recoil/atom";
import { truncate } from "../../util/common";
import BankAccountDialog from "../BankAccountDialog";
import OrderHistoryDialog from "../OrderHistoryDialog";

const MainButton = styled(Button)({
  fontSize: "15px",
  background: colors.yellowDarkPrimary,
  border: colors.boderYellow,
  color: colors.blueDarkPrimary,
  padding: "6px 16px",
  mx: 2,

  "&.MuiButton-root:hover": {
    border: colors.boderYellow,
    background: colors.yellowDarkPrimary,
    color: colors.blueDarkPrimary,
  },
});

const MenuUser: FunctionComponent = () => {
  const user = useRecoilValue(userState);
  const resetUserState = useResetRecoilState(userState);

  const [openTooltipCopiedAddress, setOpenTooltipCopiedAddress] = useState(false);
  const [openOrderHistoryDialog, setOpenOrderHistoryDialog] = useState(false);
  const [openBankAccountDialog, setOpenBankAccountDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTooltipCopiedAddressClose = () => {
    setOpenTooltipCopiedAddress(false);
  };

  const handleTooltipCopiedAddressOpen = () => {
    setOpenTooltipCopiedAddress(true);
  };

  const copyAddressToClipboard = () => {
    if (user?.wallet_address) {
      navigator.clipboard.writeText(user.wallet_address);
      handleTooltipCopiedAddressOpen();
    }
  };

  const onClickBankAccount = () => {
    setOpenBankAccountDialog(true);
  };

  const onClickTransactions = () => {
    setOpenOrderHistoryDialog(true);
  };

  const handleCloseOrderHistoryDialog = () => {
    setOpenOrderHistoryDialog(false);
  };

  const onCloseBankAccountDialog = () => {
    setOpenBankAccountDialog(false);
  };

  const onClickLogout = () => {
    resetUserState();
    localStorage.removeItem("walletAddress");
    localStorage.removeItem("jwtToken");
  };

  return (
    <>
      <MainButton disableRipple onClick={handleClick} startIcon={<MenuIcon />}>
        Account Info
      </MainButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          sx: {
            background: colors.blueDarkPrimary,
            color: colors.white,
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "24px",
          },
        }}
      >
        <ClickAwayListener onClickAway={handleTooltipCopiedAddressClose}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipCopiedAddressClose}
            open={openTooltipCopiedAddress}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            arrow
            title="Copied!"
          >
            <MenuItem onClick={copyAddressToClipboard}>{truncate(user?.wallet_address || "xxx", 12)}</MenuItem>
          </Tooltip>
        </ClickAwayListener>
        <MenuItem onClick={onClickBankAccount}>BANK ACCOUNT</MenuItem>
        <MenuItem onClick={onClickTransactions}>TRANSACTIONS</MenuItem>
        <MenuItem onClick={onClickLogout}>SIGN OUT</MenuItem>
      </Menu>

      <BankAccountDialog open={openBankAccountDialog} onClose={onCloseBankAccountDialog}></BankAccountDialog>

      <OrderHistoryDialog open={openOrderHistoryDialog} onClose={handleCloseOrderHistoryDialog}></OrderHistoryDialog>
    </>
  );
};

export default MenuUser;
