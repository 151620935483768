import CloseIcon from "@mui/icons-material/Close";
import { DialogTitle, IconButton } from "@mui/material";
import { FunctionComponent } from "react";
import { colors } from "../../../muiTheme";

export interface DialogTitleWithCloseProps {
  children?: React.ReactNode;
  onClose: () => void;
  otherProps?: any;
}

const DialogTitleWithClose: FunctionComponent<DialogTitleWithCloseProps> = ({
  children,
  onClose,
  otherProps = {},
}) => {
  return (
    <DialogTitle
      sx={{
        m: 0,
        p: "40px 16px 24px 24px",
        color: colors.white,
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "32px",
        letterSpacing: "0.15px",
      }}
      {...otherProps}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          disableRipple
          sx={{
            position: "absolute",
            right: 12,
            top: 12,
            color: colors.white,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default DialogTitleWithClose;
