import { atom } from "recoil";
import { Transfer } from "../typings/model";
import { User } from "../typings/model.api";

export const errorState = atom<any>({
  key: "errorState",
  default: undefined,
});

export const loadingState = atom<boolean>({
  key: "loadingState",
  default: false,
});

export const toastStateState = atom<boolean>({
  key: "toastStateState",
  default: false,
});

export const toastContentState = atom<string>({
  key: "toastContentState",
  default: "",
});

export const userState = atom<User | undefined>({
  key: "userState",
  default: undefined,
});

export const transferState = atom<Transfer | undefined>({
  key: "transferState",
  default: undefined,
});

export const addBankAccountState = atom<boolean>({
  key: "addBankAccountState",
  default: false,
});

export const openQuickPayDialogState = atom<boolean>({
  key: "openQuickPayDialogState",
  default: false,
});
