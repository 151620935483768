import { FC, lazy, Suspense, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Redirect, Route, Switch } from "react-router-dom";
import Loading from "./components/Loading";

import "./App.css";
import LoadingSpinner from "./components/core/LoadingSpinner";
import ToastAlert from "./components/core/ToastAlert";
import Header from "./components/Header";
import useLoadWeb3 from "./hooks/useLoadWeb3";
import ErrorFallback from "./pages/Error/ErrorFallback";
import { isMobile } from 'react-device-detect';

const SystemError = lazy(() => import("./pages/Error/SystemError"));
const Home = lazy(() => import("./pages/Home"));
const Trade = lazy(() => import("./pages/Trade"));
const OrderDetail = lazy(() => import("./pages/OrderDetail"));

const App: FC = () => {
  const { checkConnection } = useLoadWeb3();

  useEffect(() => {
    const walletAddress = localStorage.getItem("walletAddress");
    
    if (walletAddress) {
      checkConnection();
    }
  }, []);

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Header />
        <Loading />
        <ToastAlert></ToastAlert>
        <Suspense fallback={<LoadingSpinner />}>
          <Switch>
            <Route exact path="/system-error" component={SystemError} />
            <Route exact path="/" component={Home} />
            <Route exact path="/trade" component={Trade} />
            <Route exact path="/order/:orderId" component={OrderDetail} />
            <Redirect to={"/"} />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </>
  );
};

export default App;
