import { Backdrop, CircularProgress, styled } from "@mui/material";
import { FunctionComponent } from "react";
import { colors } from "../../../muiTheme";

interface LoadingSpinnerProps {
  hide?: boolean;
  zIndex?: number;
}

const BackDropOverlay = styled(Backdrop)<LoadingSpinnerProps>`
  z-index: ${(props) => props.zIndex || 1400};
`;

const LoadingSpinner: FunctionComponent<LoadingSpinnerProps> = ({
  hide = false,
  ...props
}) => {
  return !hide ? (
    <BackDropOverlay transitionDuration={0} open {...props}>
      <CircularProgress sx={{ color: colors.yellowDarkPrimary }} size={48} />
    </BackDropOverlay>
  ) : null;
};

export default LoadingSpinner;
